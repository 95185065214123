<template>
	<div>
		<div class="tel">
			<img src="../../assets/image/ID.png" alt="" style="width: 23.5PX;height: 16.9PX;margin-top: 4PX;">
			<p class="telnumber">证件类型：</p>
			<el-select placeholder="请选择" v-model="idtype" style="margin-left: 34PX;" :disabled="disabled">
				<el-option v-for="item in idCardType" :key="item.itemValue" :label="item.itemText" :value="item.itemValue">
				</el-option>
			</el-select>
		</div>

		<div v-if="idtype == '01' || idtype == '06' || idtype == '07' " >
			<ldentityverification :edit="edit" :distinguish="distinguish" :idtype="idtype" @authorization="authorization" @getreturn="getreturn" @getdistinguish="getdisabled"></ldentityverification>
		</div>
		<div v-else>
			<ldentityverifications :edit="edit" :distinguish="distinguish" :idtype="idtype" @toexamine="toexamine"></ldentityverifications>
		</div>
	</div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
import ldentityverification from './ldentityverification.vue'
import ldentityverifications from './ldentityverifications.vue'
export default {
	name: '',
	components: {
		SingleImage,
		ldentityverification,
		ldentityverifications
	},
	props: {
		edit: {
			type: Boolean,
			default: false
		},
		distinguish: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			idtype: this.$store.state.realnameornot.idtype || '01',
			idCardType: [],
			disabled:false
		}
	},
	created() {
		this.getIdCardType()
	},	
	methods: {
		getIdCardType() {
			this.$api.idCardType({ dictValue: '其他' }).then(res => {
				this.idCardType = res.data.data.result
			})
		},
		authorization(){
			this.$emit('authorization')
		},
		getreturn(){
			this.$emit('toexamine')
		},
		toexamine(){
			this.$emit('toexamine')
		},
		getdisabled(){
			this.disabled = !this.disabled
		}
	}
}
</script>

<style lang="scss" scoped>
.tel {
	display: flex;
	padding-top: 27PX;
	margin-left: 47PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 10PX;
	flex-shrink: 0
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
	margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
	margin-left: 35PX;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 195PX;
	height: 127PX;
	border: 1PX dashed #2368E1;
}

.example {
	display: flex;
}

.example p {
	font-size: 18PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 77PX;
	margin-top: 0 !important;
	flex-shrink: 0;
}

.example img {
	width: 194PX;
	height: 145PX;
	border-radius: 16PX;
	margin-left: 31PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: rgba(37, 44, 53, 0.1);
	height: 1PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin-left: 116PX;
	margin-top: 87PX;
	margin-bottom: 57PX;
}

.radio .sp {
	color: #E6101C;
	margin-right: 8PX;
	font-size: 25PX;
}


.radio p {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
}

.button {
	display: flex;
	margin-bottom: 100PX;
	justify-content: center;
}

.save {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.submitt {
	width: 320.2PX;
	height: 67PX;
	background: #A2BAD5;
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

::v-deep .el-input__icon {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding-left: 15PX;
}
.briefintroduction{
	color: #8b8c90;
	margin-left: 140PX;
	font-size: 18PX;
	margin-right: 10PX;
}
</style>