<template>
	<div>
		<div class="tel">
			<p class="telnumber" style="margin-left: 0;">个人免冠照片：</p>
			<div class="upload-box">
				<div class="upload">
					<SingleImage @uploadSuccess="handleSuccess1" :fileList="fileList1" @uploaddelete="uploaddelete">
					</SingleImage>
					<div class="example">
						<p>正确示范</p>
						<img src="../../assets/image/brand.jpg" alt="" style="width: 102PX;">
					</div>
					<div class="briefintroduction">
						<div>请上传本人近期正面、免冠电子证件照（背景需为纯色）。</div>
						<div>请上传符合要求的照片，否则会影响后续实名认证审核。</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tel">
			<div style="color: red;">*</div>
			<p class="telnumber">照片公示授权：</p>
			<el-radio label="1" v-model="radio2" style="margin-left: 17PX;"></el-radio>
			<p style="font-size: 15PX;">我同意北京市体育竞赛管理和国际交流中心使用我所提交的个人免冠照片作为官网裁判员注册信息公示及运动员成绩查询等栏目的展示，以便使用裁判员或运动员相关服务。</p>
		</div>

		<div class="line"></div>
		<div class="tel">
			<p class="telnumber" style="margin-left: 0;margin-right: 18px;">证件页面一：</p>
			<div class="upload-box">
				<div class="upload">
					<SingleImage @uploadSuccess="handleSuccess2" :fileList="fileList2" @uploaddelete="uploaddelete">
					</SingleImage>
					<div class="example" style="margin-left: 77PX;align-items: center;font-size: 18PX ">
						请上传包含个人信息的证件第一页
					</div>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="tel">
			<p class="telnumber" style="margin-left: 0;margin-right: 18px;">证件页面二：</p>
			<div class="upload-box">
				<div class="upload">
					<SingleImage @uploadSuccess="handleSuccess3" :fileList="fileList3" @uploaddelete="uploaddelete">
					</SingleImage>
					<div class="example" style="margin-left: 77PX;align-items: center;font-size: 18PX ">
						请上传包含个人信息的证件第二页
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="line"></div> -->
		<!-- 	<div class="tel">
			<p class="telnumber" style="margin-left: 0;">手持身份证：</p>
			<div class="upload-box" style="margin-left: 53PX">
				<div class="upload">
					<SingleImage @uploadSuccess="handleSuccess4" :fileList="fileList4" @uploaddelete="uploaddelete"></SingleImage>
					<div class="example">
						<p>正确示范</p>
						<img src="../../assets/image/shouchi.jpg" alt="">
					</div>
				</div>
			</div>
		</div> -->

		<div class="tel">
			<img src="../../assets/image/ID.png" alt="" style="width: 23.5PX;height: 16.9PX;margin-top: 4PX;">
			<p class="telnumber">证件号码：</p>
			<input style="margin-left: 34PX;" v-model="info.cardnum" @blur="getcardnum" @input="formatIdCard"
				maxlength="30">
		</div>
		<div class="tel">
			<img src="../../assets/image/user.png" alt="" style="width: 20PX;height: 23PX;">
			<p class="telnumber">真实姓名：</p>
			<input type="text" v-model="info.realname">
		</div>
		<div class="tel">
			<img src="../../assets/image/tel.png" alt="" style="width: 15PX;height: 23PX;margin-top: 2PX;">
			<p class="telnumber" style="margin-right: 23PX;">手机号：</p>
			<input type="text" v-model="info.phone" disabled>
		</div>
		<div class="tel">
			<img src="../../assets/image/gender.png" alt="" style="width: 18.9PX;height: 19PX;margin-top: 4PX;">
			<p class="telnumber" style="margin-right: 75PX;">性别：</p>
			<el-select placeholder="请选择" v-model="info.sex" clearable>
				<el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>
		<div class="tel">
			<img src="../../assets/image/dateofbirth.png" alt="" style="width: 21.9PX;height: 21.9PX;margin-top: 2PX;">
			<p class="telnumber" style="margin-right: 36PX;">出生日期：</p>
			<el-date-picker :picker-options="pickerOptions" v-model="info.birth" type="date" placeholder="请选择"
				format="yyyy-MM-dd" value-format="yyyy-MM-dd">
			</el-date-picker>
		</div>
		<div class="tel">
			<img src="../../assets/image/nation.png" alt="" style="width: 23.7PX;height: 20PX;margin-top: 3PX;">
			<p class="telnumber" style="margin-right: 70PX;">民族：</p>
			<el-select placeholder="请选择" v-model="info.region" filterable clearable>
				<el-option v-for="item in options" :key="item.itemValue" :label="item.itemText" :value="item.itemValue">
				</el-option>
			</el-select>
		</div>

		<div class="radio">
			<el-radio label="1" v-model="radio"></el-radio>
			<div class="sp">*</div>
			<div>
				<p>我同意北京市体育竞赛管理和国际交流中心使用我所提交的信息注册北京市体育克赛管理系统，以及完成身份核验，以便使用北京市体育竟赛管理系统相关服务。</p>
				<p>我已认真阅读并同意<a class="jumpto" :href="VUE_APP_BASE_USER" target="_blank">《用户服务协议》</a>及<a class="jumpto" :href="VUE_APP_BASE_PRIVACY" target="_blank">《隐私政策》</a>。</p>
			</div>
		</div>
		<div class="button">
			<div class="save" @click="save">提交审核</div>
			<!-- <div class="submitt">取消</div> -->
		</div>
	</div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
export default {
	name: '',
	components: {
		SingleImage
	},
	props: {
		edit: {
			type: Boolean,
			default: false
		},
		distinguish: {
			type: [String, Number],
			default: 0
		},
		idtype: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			options: [],
			radio: '',
			radio2: '',
			gender: [{
				label: '男',
				value: 1
			},
			{
				label: '女',
				value: 2
			}],
			info: {
				userid: '',
				cardnum: '',
				birth: '',
				sex: '',
				phone: '',
				cardBackPic: '',
				cardFrontPic: '',
				handPic: '',
				headPic: '',
				realname: '',
				region: '',
				id: '',
				idtype: this.idtype
			},
			fileList1: [],
			fileList2: [],
			fileList3: [],
			fileList4: [],
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				}
			},
			VUE_APP_BASE_USER:process.env.VUE_APP_BASE_USER,
			VUE_APP_BASE_PRIVACY:process.env.VUE_APP_BASE_PRIVACY
		}
	},
	watch: {
		idtype: {
			handler(newVal) {
				this.info.idtype = newVal
			}
		}
	},
	created() {
		this.sysUserRegion()
		if (this.edit) {
			this.info = {
				cardnum: this.$store.state.realnameornot.cardnum,
				birth: this.$store.state.realnameornot.birth,
				sex: Number(this.$store.state.realnameornot.sex),
				phone: '',
				cardBackPic: this.$store.state.realnameornot.cardBackPic,
				cardFrontPic: this.$store.state.realnameornot.cardFrontPic,
				// handPic: this.$store.state.realnameornot.handPic,
				headPic: this.$store.state.realnameornot.headPic,
				realname: this.$store.state.realnameornot.realname,
				region: this.$store.state.realnameornot.region,
				idtype: this.idtype
			}
			if (this.$store.state.realnameornot.headPic != null) {
				this.fileList1 = [{
					name: this.$store.state.realnameornot.headPic,
					url: this.$store.state.realnameornot.headPic
				}]
			}
			if (this.$store.state.realnameornot.cardFrontPic != null) {
				this.fileList2 = [{
					name: this.$store.state.realnameornot.cardFrontPic,
					url: this.$store.state.realnameornot.cardFrontPic
				}]
			}
			if (this.$store.state.realnameornot.cardBackPic != null) {
				this.fileList3 = [{
					name: this.$store.state.realnameornot.cardBackPic,
					url: this.$store.state.realnameornot.cardBackPic
				}]
			}
			// if (this.$store.state.realnameornot.handPic != null) {
			// 	this.fileList4 = [{
			// 		name: this.$store.state.realnameornot.handPic,
			// 		url: this.$store.state.realnameornot.handPic
			// 	}]
			// }
		}
		this.userInfo()
	},
	methods: {
		userInfo() {
			let date = {
				phone: this.$store.state.logininformation.phone,
				username: this.$store.state.logininformation.username,
				realname: this.$store.state.logininformation.realname
			}
			this.$api.userInfo(date).then(res => {
				res.data.data.phone = this.$decrypt(res.data.data.phone)
				res.data.data.realname = this.$decrypt(res.data.data.realname)
				res.data.data.username = this.$decrypt(res.data.data.username)
				this.info.phone = res.data.data.phone
			})
		},
		sysUserRegion() {
			this.$api.sysUserRegion().then(res => {
				this.options = res.data.data.result
			})
		},
		handleSuccess1(e) { // 个人免冠照片
			this.info.headPic = e.message
		},
		handleSuccess2(e) { // 身份证人像面
			this.info.cardFrontPic = e.message
		},
		handleSuccess3(e) { // 身份证国徽面
			this.info.cardBackPic = e.message
		},
		handleSuccess4(e) { // 手持身份证
			this.info.handPic = e.message
		},
		uploaddelete(e) {
			if (e == this.info.headPic) {
				this.info.headPic = ''
			} else if (e == this.info.cardBackPic) {
				this.info.cardBackPic = ''
			} else if (e == this.info.cardFrontPic) {
				this.info.cardFrontPic = ''
			} else if (e == this.info.handPic) {
				this.info.handPic = ''
			}
		},
		// 验证身份证是否正确
		validateIdCard(idCard) {
			// 校验身份证号码长度
			if (idCard.length !== 15 && idCard.length !== 18) {
				return false
			}

			// 校验前面的数字部分
			const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
			if (!pattern.test(idCard)) {
				return false
			}

			// 统一转换为 18 位身份证号码
			let idCard18 = idCard;
			if (idCard.length === 15) {
				idCard18 = this.convertTo18IdCard(idCard);
			}

			// 校验最后一位校验位
			const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
			const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
				return sum + parseInt(digit) * factors[index];
			}, 0) % 11;
			const checkCode = '10X98765432'; // 校验码映射
			if (idCard18[17] !== checkCode[checkSum]) {
				return false
			}

			// 验证通过
			return true
		},
		// 验证身份证 出生日期 性别 
		getBirthdayAndGender(idCard) {
			// 校验身份证号码长度
			if (idCard.length !== 15 && idCard.length !== 18) {
				return null;
			}

			// 校验前面的数字部分
			const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
			if (!pattern.test(idCard)) {
				return null;
			}

			// 统一转换为 18 位身份证号码
			if (idCard.length === 15) {
				idCard = this.convertTo18IdCard(idCard);
			}

			// 校验最后一位校验位
			const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
			const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
				return sum + parseInt(digit) * factors[index];
			}, 0) % 11;
			const checkCode = '10X98765432'; // 校验码映射
			if (idCard[17] !== checkCode[checkSum]) {
				return null;
			}

			// 提取出生日期
			const birthDate = idCard.slice(6, 14);
			if (!/^\d{8}$/.test(birthDate)) {
				return null;
			}
			const year = parseInt(birthDate.slice(0, 4));
			const month = parseInt(birthDate.slice(4, 6));
			const day = parseInt(birthDate.slice(6, 8));

			// 提取性别
			const genderCode = parseInt(idCard[16]);
			const gender = genderCode % 2 === 0 ? 2 : 1;

			// 返回生日和性别信息
			return {
				birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
				gender: gender
			};
		},
		// 转换为 18 位身份证号码
		convertTo18IdCard(idCard) {
			if (idCard.length === 18) {
				return idCard;
			}
			const century = new Date().getFullYear() % 100;
			const year = parseInt(idCard.slice(6, 8));
			let prefix = '';

			if (year <= century) {
				prefix = '20';
			} else {
				prefix = '19';
			}
			return idCard.slice(0, 6) + prefix + idCard.slice(6);
		},
		getcardnum() {
			if (this.info.idtype == '01') {
				this.info.birth = this.getBirthdayAndGender(this.info.cardnum).birthday
				this.info.sex = this.getBirthdayAndGender(this.info.cardnum).gender
			}
		},
		formatIdCard() {
			this.info.cardnum = this.info.cardnum.replace(/x/gi, 'X')
		},
		save() {
			this.info.userid = this.$store.state.userid
			const regex = /^1[3456789]\d{9}$/

			if (this.$verification(this.info.headPic)) {
				return this.$message.error('请上传个人免冠照片')
			}
			else if (this.$verification(this.info.cardFrontPic)) {
				return this.$message.error('请上传证件页面一')
			}
			else if (this.$verification(this.info.cardBackPic)) {
				return this.$message.error('请上传证件页面二')
			}
			else if (this.$verification(this.info.idtype)) {
				return this.$message.error('请选择证件类型')
			}
			else if (this.$verification(this.info.realname)) {
				return this.$message.error('请输入真实姓名')
			}
			else if (this.$verification(this.info.phone)) {
				return this.$message.error('请输入手机号')
			}
			else if (!regex.test(this.info.phone)) {
				return this.$message.error('手机号码格式错误')
			}
			else if (this.$verification(this.info.sex)) {
				return this.$message.error('请选择性别')
			}
			else if (this.$verification(this.info.birth)) {
				return this.$message.error('请选择出生日期')
			}
			else if (this.$verification(this.info.cardnum)) {
				return this.$message.error('请输入证件号码')
			}
			else if (this.$verification(this.info.region)) {
				return this.$message.error('请选择民族')
			}
			else if (this.radio2 == 0) {
				return this.$message.error('请勾选照片公示授权')
			}
			else if (this.radio == 0) {
				return this.$message.error('请勾选下方本人承诺')
			}

			if (this.distinguish == 0) {
				let encryptedInfo = {
					userid: this.info.userid,
					cardnum: this.$encrypt(this.info.cardnum),
					birth: this.info.birth.replace(/-/g, ""),
					sex: this.info.sex,
					phone: this.$encrypt(this.info.phone),
					cardBackPic: this.info.cardBackPic,
					cardFrontPic: this.info.cardFrontPic,
					// handPic: this.$encrypt(this.info.handPic),
					headPic: this.info.headPic,
					realname: this.$encrypt(this.info.realname),
					region: this.info.region,
					id: this.info.id,
					idtype: this.info.idtype
				}

				this.$api.submitPeopleAuthen(encryptedInfo).then(res => {
					this.$message.success('提交成功')
					this.$store.commit("EXIT_REALN")
					setTimeout(() => {
						this.$emit('toexamine')
						this.info = {
							userid: '',
							cardnum: '',
							birth: '',
							sex: '',
							phone: '',
							cardBackPic: '',
							cardFrontPic: '',
							handPic: '',
							headPic: '',
							realname: '',
							region: '',
							idtype: this.idtype
						}
					}, 500)
				})
			} else {
				this.info.id = this.$store.state.realnameornot.id

				let encryptedInfo = {
					userid: this.info.userid,
					cardnum: this.$encrypt(this.info.cardnum),
					birth: this.info.birth.replace(/-/g, ""),
					sex: this.info.sex,
					phone: this.$encrypt(this.info.phone),
					cardBackPic: this.info.cardBackPic,
					cardFrontPic: this.info.cardFrontPic,
					// handPic: this.$encrypt(this.info.handPic),
					headPic: this.info.headPic,
					realname: this.$encrypt(this.info.realname),
					region: this.info.region,
					id: this.info.id,
					idtype: this.info.idtype
				}

				this.$api.resubmitPeopleAuthen(encryptedInfo).then(res => {
					this.$message.success('提交成功')
					this.$store.commit("EXIT_REALN")
					setTimeout(() => {
						this.$emit('toexamine')
						this.info = {
							userid: '',
							cardnum: '',
							birth: '',
							sex: '',
							phone: '',
							cardBackPic: '',
							cardFrontPic: '',
							handPic: '',
							headPic: '',
							realname: '',
							region: '',
							idtype: this.idtype
						}
					}, 500)
				})
			}

		}
	}
}
</script>

<style lang="scss" scoped>
.tel {
	display: flex;
	padding-top: 27PX;
	margin-left: 47PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 10PX;
	flex-shrink: 0
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
	margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
	margin-left: 35PX;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 195PX;
	height: 127PX;
	border: 1PX dashed #2368E1;
}

.example {
	display: flex;
}

.example p {
	font-size: 18PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 77PX;
	margin-top: 0 !important;
	flex-shrink: 0;
}

.example img {
	width: 194PX;
	height: 145PX;
	border-radius: 16PX;
	margin-left: 31PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: rgba(37, 44, 53, 0.1);
	height: 1PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin-left: 116PX;
	margin-top: 87PX;
	margin-bottom: 57PX;
}

.radio .sp {
	color: #E6101C;
	margin-right: 8PX;
	font-size: 25PX;
}


.radio p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
}

.jumpto {
	color: #095FFF;
	cursor: pointer;
}

.button {
	display: flex;
	margin-bottom: 100PX;
	justify-content: center;
}

.save {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.submitt {
	width: 320.2PX;
	height: 67PX;
	background: #A2BAD5;
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

::v-deep .el-input__icon {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding-left: 15PX;
}

.briefintroduction {
	color: #8b8c90;
	margin-left: 140PX;
	font-size: 18PX;
	// margin-right: 10PX;
}
</style>