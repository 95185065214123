<template>
	<div>
		<div class="line"></div>
		<div class="tel tabs">
			<div :class="verific == 1 ? 'rectangle select' : 'rectangle'">
				1.身份验证
			</div>
			<div :class="verific == 1 ? 'triangle sjselect' : 'triangle'"></div>
			<div :class="verific == 2 ? 'rectangle select ys' : 'rectangle ys'">
				2.人脸识别认证
			</div>
			<div :class="verific == 2 ? 'triangle sjselect ys' : 'triangle ys'"></div>
		</div>

		<div v-if="verific == 1">
			<div class="tel">
				<img src="../../assets/image/ID.png" alt="" style="width: 23.5PX;height: 16.9PX;margin-top: 4PX;">
				<p class="telnumber">证件号码：</p>
				<input style="margin-left: 34PX;" v-model="info.cardnum" @blur="getcardnum" @input="formatIdCard"
					maxlength="30">
			</div>
			<div class="tel">
				<img src="../../assets/image/user.png" alt="" style="width: 20PX;height: 23PX;">
				<p class="telnumber">真实姓名：</p>
				<input type="text" v-model="info.realname">
			</div>
			<div class="tel">
				<img src="../../assets/image/tel.png" alt="" style="width: 15PX;height: 23PX;margin-top: 2PX;">
				<p class="telnumber" style="margin-right: 23PX;">手机号：</p>
				<input type="text" v-model="info.phone" disabled>
			</div>
			<div class="tel">
				<img src="../../assets/image/gender.png" alt="" style="width: 18.9PX;height: 19PX;margin-top: 4PX;">
				<p class="telnumber" style="margin-right: 75PX;">性别：</p>
				<el-select placeholder="请选择" v-model="info.sex" clearable>
					<el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="tel">
				<img src="../../assets/image/dateofbirth.png" alt=""
					style="width: 21.9PX;height: 21.9PX;margin-top: 2PX;">
				<p class="telnumber" style="margin-right: 36PX;">出生日期：</p>
				<el-date-picker :picker-options="pickerOptions" v-model="info.birth" type="date" placeholder="请选择"
					format="yyyy-MM-dd" value-format="yyyy-MM-dd">
				</el-date-picker>
			</div>
			<div class="tel">
				<img src="../../assets/image/nation.png" alt="" style="width: 23.7PX;height: 20PX;margin-top: 3PX;">
				<p class="telnumber" style="margin-right: 70PX;">民族：</p>
				<el-select placeholder="请选择" v-model="info.region" filterable clearable>
					<el-option v-for="item in options" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
				</el-select>
			</div>

			<div class="radio">
				<el-radio label="1" v-model="radio"></el-radio>
				<div class="sp">*</div>
				<div>
					<p>我同意北京市体育竞赛管理和国际交流中心使用我所提交的信息注册北京市体育克赛管理系统，以及完成身份核验，以便使用北京市体育竟赛管理系统相关服务。</p>
					<p>我已认真阅读并同意<a class="jumpto" :href="VUE_APP_BASE_USER" target="_blank">《用户服务协议》</a>及<a class="jumpto" :href="VUE_APP_BASE_PRIVACY" target="_blank">《隐私政策》</a>。</p>
				</div>
			</div>
			<div class="button">
				<div class="save" @click="save">下一步</div>
			</div>
		</div>
		<div v-if="verific == 2">

			<div class="qrbox" v-if="!success">
				<canvas id="QRCode_header" class="qr" @click="getqrs"></canvas>
				<p>请使用微信或支付宝"扫一扫"功能，扫描上方二维码，进行人脸识别。</p>
				<div class="imgbox" v-if="fail">
					<img src="../../assets/image/error.png" alt="">
					<p>人脸认证失败！请点击二维码刷新重试</p>
				</div>
				<div class="button">
					<div class="save" @click="getreturn">返回</div>
				</div>
			</div>

			<div class="success" v-if="success">
				<div class="imgbox">
					<img src="../../assets/image/success.png" alt="">
					<p>恭喜认证成功</p>
				</div>
				<div class="title">如果您是网站访客，可点击“返回”按钮或直接浏览网站获取所需信息。</div>
				<div class="title">如果您是裁判员或运动员，请点击“照片授权”，按步骤进行相关操作（如无特殊需要，请勿重复操作）。</div>
				<div class="butbox">
					<div class="button">
						<div class="save" @click="getreturn">返回</div>
					</div>
					<div class="button">
						<div class="save" @click="authorization">照片授权</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
import QRCode from "qrcode";
import { sendWebsocket, closeWebsocket } from '@/utils/websocket.js'
export default {
	name: '',
	components: {
		SingleImage
	},
	props: {
		edit: {
			type: Boolean,
			default: false
		},
		distinguish: {
			type: [String, Number],
			default: 0
		},
		idtype: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			options: [],
			radio: '',
			gender: [{
				label: '男',
				value: 1
			},
			{
				label: '女',
				value: 2
			}],
			info: {
				userid: '',
				cardnum: '',
				birth: '',
				sex: '',
				phone: '',
				cardBackPic: '',
				cardFrontPic: '',
				handPic: '',
				headPic: '',
				realname: '',
				region: '',
				id: '',
				idtype: this.idtype
			},
			fileList1: [],
			fileList2: [],
			fileList3: [],
			fileList4: [],
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				}
			},
			verific: 1,
			qrUrl: "",
			success: false, // 人脸失败是否成功
			fail: false, // 失败提示
			VUE_APP_BASE_USER:process.env.VUE_APP_BASE_USER,
			VUE_APP_BASE_PRIVACY:process.env.VUE_APP_BASE_PRIVACY
		}
	},
	watch: {
		idtype: {
			handler(newVal) {
				this.info.idtype = newVal
			}
		}
	},
	created() {
		this.sysUserRegion()
		if (this.edit) {
			this.info = {
				cardnum: this.$store.state.realnameornot.cardnum,
				birth: this.$store.state.realnameornot.birth,
				sex: Number(this.$store.state.realnameornot.sex),
				phone: '',
				realname: this.$store.state.realnameornot.realname,
				region: this.$store.state.realnameornot.region,
				idtype: this.idtype
			}
		}
		this.userInfo()

		closeWebsocket()
					
		let obj = {
			userId:this.$store.state.userid
		}

		if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
			sendWebsocket('wss://'+ process.env.VUE_APP_BASE_WEB + '/websocket/', obj, this.wsMessage, this.wsError)
		} else {
			sendWebsocket('ws://'+ process.env.VUE_APP_BASE_WEB + '/websocket/', obj, this.wsMessage, this.wsError)
		}

	},
	methods: {
		userInfo() {
			let date = {
				phone: this.$store.state.logininformation.phone,
				username: this.$store.state.logininformation.username,
				realname: this.$store.state.logininformation.realname
			}
			this.$api.userInfo(date).then(res => {
				res.data.data.phone = this.$decrypt(res.data.data.phone)
				res.data.data.realname = this.$decrypt(res.data.data.realname)
				res.data.data.username = this.$decrypt(res.data.data.username)
				this.info.phone = res.data.data.phone
			})
		},
		sysUserRegion() {
			this.$api.sysUserRegion().then(res => {
				this.options = res.data.data.result
			})
		},
		// 验证身份证是否正确
		validateIdCard(idCard) {
			// 校验身份证号码长度
			if (idCard.length !== 15 && idCard.length !== 18) {
				return false
			}

			// 校验前面的数字部分
			const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
			if (!pattern.test(idCard)) {
				return false
			}

			// 统一转换为 18 位身份证号码
			let idCard18 = idCard;
			if (idCard.length === 15) {
				idCard18 = this.convertTo18IdCard(idCard);
			}

			// 校验最后一位校验位
			const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
			const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
				return sum + parseInt(digit) * factors[index];
			}, 0) % 11;
			const checkCode = '10X98765432'; // 校验码映射
			if (idCard18[17] !== checkCode[checkSum]) {
				return false
			}

			// 验证通过
			return true
		},
		// 验证身份证 出生日期 性别 
		getBirthdayAndGender(idCard) {
			// 校验身份证号码长度
			if (idCard.length !== 15 && idCard.length !== 18) {
				return null;
			}

			// 校验前面的数字部分
			const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
			if (!pattern.test(idCard)) {
				return null;
			}

			// 统一转换为 18 位身份证号码
			if (idCard.length === 15) {
				idCard = this.convertTo18IdCard(idCard);
			}

			// 校验最后一位校验位
			const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
			const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
				return sum + parseInt(digit) * factors[index];
			}, 0) % 11;
			const checkCode = '10X98765432'; // 校验码映射
			if (idCard[17] !== checkCode[checkSum]) {
				return null;
			}

			// 提取出生日期
			const birthDate = idCard.slice(6, 14);
			if (!/^\d{8}$/.test(birthDate)) {
				return null;
			}
			const year = parseInt(birthDate.slice(0, 4));
			const month = parseInt(birthDate.slice(4, 6));
			const day = parseInt(birthDate.slice(6, 8));

			// 提取性别
			const genderCode = parseInt(idCard[16]);
			const gender = genderCode % 2 === 0 ? 2 : 1;

			// 返回生日和性别信息
			return {
				birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
				gender: gender
			};
		},
		// 转换为 18 位身份证号码
		convertTo18IdCard(idCard) {
			if (idCard.length === 18) {
				return idCard;
			}
			const century = new Date().getFullYear() % 100;
			const year = parseInt(idCard.slice(6, 8));
			let prefix = '';

			if (year <= century) {
				prefix = '20';
			} else {
				prefix = '19';
			}
			return idCard.slice(0, 6) + prefix + idCard.slice(6);
		},
		getcardnum() {
			if (this.info.idtype == '01') {
				this.info.birth = this.getBirthdayAndGender(this.info.cardnum).birthday
				this.info.sex = this.getBirthdayAndGender(this.info.cardnum).gender
			}
		},
		formatIdCard() {
			this.info.cardnum = this.info.cardnum.replace(/x/gi, 'X')
		},
		save() {
			this.info.userid = this.$store.state.userid
			const regex = /^1[3456789]\d{9}$/

			if (this.$verification(this.info.realname)) {
				return this.$message.error('请输入真实姓名')
			}
			else if (this.$verification(this.info.phone)) {
				return this.$message.error('请输入手机号')
			}
			else if (!regex.test(this.info.phone)) {
				return this.$message.error('手机号码格式错误')
			}
			else if (this.$verification(this.info.sex)) {
				return this.$message.error('请选择性别')
			}
			else if (this.$verification(this.info.birth)) {
				return this.$message.error('请选择出生日期')
			}
			else if (this.$verification(this.info.cardnum)) {
				return this.$message.error('请输入证件号码')
			}
			else if (!this.validateIdCard(this.info.cardnum)) {
				return this.$message.error('证件号码格式错误')
			}
			else if (this.$verification(this.info.region)) {
				return this.$message.error('请选择民族')
			}
			else if (this.getBirthdayAndGender(this.info.cardnum).birthday != this.info.birth) {
				return this.$message.error('出生日期与身份证上不符合')
			} else if (this.getBirthdayAndGender(this.info.cardnum).gender != this.info.sex) {
				return this.$message.error('性别与身份证实际不符合')
			} else if (this.radio == 0) {
				return this.$message.error('请勾选下方本人承诺')
			}
			this.personalVerification()
		},
		personalVerification(){ // 人脸
			let data = {
				idtype: this.info.idtype,
				name: this.info.realname,
				idNum:this.info.cardnum,
				userid:this.info.userid,
				cardnum: this.$encrypt(this.info.cardnum),
				birth: this.info.birth.replace(/-/g, ""),
				sex: this.info.sex,
				phone: this.$encrypt(this.info.phone),
				realname: this.$encrypt(this.info.realname),
				region: this.info.region,
				id: this.info.id,
			}
			this.$api.personalVerification(data).then(cer=>{
				if(cer.data.data.code == 200){
					this.verific = 2
					this.$emit('getdistinguish')
					setTimeout(() => {
						this.qrUrl = cer.data.data.result.url;
						this.getqr()
					}, 500)
				}
			})
		},
		wsMessage (data) {
			console.log('结果',data)
			if(!this.$verification(data.code)){
				if(data.code != 200){
					this.success = false
					this.fail = true
				}else{
					this.success = true
					this.fail = false
				}
			}
			this.$store.commit("EXIT_REALN")
		},
		// ws连接失败，组件要执行的代码
		wsError () {},
		getqrs(){
			this.personalVerification()
			this.getqr()
			this.fail = false
		},
		getqr() {
			let opts = {
				errorCorrectionLevel: "H", //容错级别
				type: "image/png", //生成的二维码类型
				quality: 0.3, //二维码质量
				margin: 0, //二维码留白边距
				width: 280, //宽
				height: 280, //高
				text: this.qrUrl, //二维码内容
				color: {
					dark: "#333333", //前景色
					light: "#fff", //背景色
				},
			};

			let msg = document.getElementById("QRCode_header");
			// 将获取到的数据（val）画到msg（canvas）上
			QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
				if (error) {
					console.log("二维码加载失败", error);
					this.$message.error("二维码加载失败");
				}
			});
		},
		getreturn() {
			closeWebsocket()
			this.$emit('getreturn')
		},
		authorization() {
			closeWebsocket()
			this.$emit('authorization')
		}
	}
}
</script>

<style lang="scss" scoped>
.tel {
	display: flex;
	padding-top: 27PX;
	margin-left: 47PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 10PX;
	flex-shrink: 0
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
	margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
	margin-left: 35PX;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 195PX;
	height: 127PX;
	border: 1PX dashed #2368E1;
}

.example {
	display: flex;
}

.example p {
	font-size: 18PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 77PX;
	margin-top: 0 !important;
	flex-shrink: 0;
}

.example img {
	width: 194PX;
	height: 145PX;
	border-radius: 16PX;
	margin-left: 31PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: rgba(37, 44, 53, 0.1);
	height: 1PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin-left: 116PX;
	margin-top: 87PX;
	margin-bottom: 57PX;
}

.radio .sp {
	color: #E6101C;
	margin-right: 8PX;
	font-size: 25PX;
}


.radio p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
}

.jumpto {
	color: #095FFF;
	cursor: pointer;
}

.button {
	display: flex;
	margin-bottom: 100PX;
	justify-content: center;
}

.save {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.submitt {
	width: 320.2PX;
	height: 67PX;
	background: #A2BAD5;
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

::v-deep .el-input__icon {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding-left: 15PX;
}

.briefintroduction {
	color: #8b8c90;
	margin-left: 140PX;
	font-size: 18PX;
	margin-right: 10PX;
}


.rectangle {
	position: relative;
	width: 300PX;
	height: 50PX;
	border: 1PX solid #095FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 18PX;
	border-right: 0;
}

.select {
	background: #095FFF;
	color: #FFFFFF;
}

.triangle {
	width: 0;
	height: 0;
	border-top: 25PX solid transparent;
	border-bottom: 25PX solid transparent;
	border-left: 25PX solid #095FFF;
	position: relative;
	z-index: 999;
}

.triangle::after {
	content: '';
	position: absolute;
	top: -24PX;
	left: -25PX;
	width: 0;
	height: 0;
	border-top: 24PX solid transparent;
	border-bottom: 24PX solid transparent;
	border-left: 24PX solid #F8FBFF;
}

.ys {
	position: relative;
	left: -25PX;
}

.sjselect::after {
	border-left: none !important;
}

.qrbox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40PX;

	.qr {
		width: 200PX !important;
		height: 200PX !important;
	}

	.imgbox {
		margin-bottom: 40PX;
		display: flex;

		img {
			width: 30PX;
			height: 30PX;
		}

		P {
			font-size: 21PX;
			margin-bottom: 0;
			margin-left: 10PX;
			color: red;
			margin-top: 0;
		}
	}

	p {
		font-size: 18PX;
		margin: 40PX 0;
	}
}

.success {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.imgbox {
		margin: 40PX 0;
		display: flex;

		img {
			width: 30PX;
			height: 30PX;
		}

		P {
			font-size: 21PX;
			margin-bottom: 0;
			margin-left: 10PX;
			color: #52c41a;
		}
	}

	.title {
		font-size: 18PX;
		margin-bottom: 10PX;
		width:702PX;
	}

	.butbox {
		margin-top: 40PX;
		display: flex;
		justify-content: space-around;

		.button:nth-child(1) {
			margin-right: 40PX;
		}
	}
}
</style>