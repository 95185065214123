import { Message } from 'element-ui'

let websock = null
let messageCallback = null
let errorCallback = null
let wsUrl = ''
let tryTime = 0
let heartbeatTimer = null // 心跳检测计时器
let userid = null

// 接收ws后端返回的数据
function websocketonmessage (e) { 
  messageCallback(JSON.parse(e.data))
}

// 发起websocket连接
function websocketSend (agentData) {
  // 加延迟是为了尽量让ws连接状态变为OPEN   
  setTimeout(() => { 
    // 添加状态判断，当为OPEN时，发送消息
    if (websock.readyState === websock.OPEN) { // websock.OPEN = 1 
      // 发给后端的数据需要字符串化
      websock.send(JSON.stringify(agentData))
    }
    if (websock.readyState === websock.CLOSED) { // websock.CLOSED = 3 
      console.log('websock.readyState=3')
      Message.error('ws连接异常，请稍候重试')
      errorCallback()
    }
  }, 500)
}

// 关闭ws连接
function websocketclose (e) {  
  console.log(e)
  // e.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
  // e.code !== 1000  表示非正常关闭。
  if (e && e.code !== 1000) {
    Message.error('ws连接异常，请稍候重试')
    errorCallback()
    // 如果需要设置异常重连则可替换为下面的代码，自行进行测试
    if (tryTime < 10) {
       setTimeout(function() {
        websock = null
        tryTime++
        initWebSocket(userid)
      }, 3 * 1000)
    } else {
      Message.error('重连失败！请稍后重试')
    }
  }
  clearInterval(heartbeatTimer) // 关闭连接后停止心跳检测
}

// 建立ws连接
function websocketOpen (e) {
  console.log('ws连接成功')
  startHeartbeat() // 连接成功后启动心跳检测
}

// 初始化weosocket
function initWebSocket (agentData) { 
  if (typeof (WebSocket) === 'undefined') {
    Message.error('您的浏览器不支持WebSocket，无法获取数据')
    return false
  }
  
  // ws请求完整地址
  const requstWsUrl = wsUrl + agentData.userId
  websock = new WebSocket(requstWsUrl)
 
  websock.onmessage = function (e) {
    websocketonmessage(e)
  } 
  websock.onopen = function () {
    websocketOpen()
  }
  websock.onerror = function () {
    errorCallback()
  }
  websock.onclose = function (e) {
    websocketclose(e)
  } 
}

// 启动心跳检测
function startHeartbeat() {
  heartbeatTimer = setInterval(() => {
    if (websock.readyState === websock.OPEN) {
      websock.send(JSON.stringify({ type: 'heartbeat' }))
    }
  }, 10000) // 每10秒发送一次心跳
}

// 发起websocket请求函数
export function sendWebsocket (url, agentData, successCallback, errCallback) { 
  wsUrl = url
  initWebSocket(agentData)
  messageCallback = successCallback
  errorCallback = errCallback
  websocketSend(agentData)
  userid = agentData
}

// 关闭websocket函数
export function closeWebsocket () {
  if (websock) {
    websock.close() // 关闭websocket
    websock.onclose() // 关闭websocket
  }
  clearInterval(heartbeatTimer) // 关闭连接后停止心跳检测
}
